<!-- menu customer shipto -->
<template>
  <vx-card title="Customer Address">
    <vs-tabs>
      <vs-tab label="Open">
        <div class="vx-row mb-6" style="width: 50%">
          <vs-button
            class="ml-4 mt-2"
            color="primary"
            icon-pack="feather"
            icon="icon-plus"
            @click="handleCreate()"
            v-if="this.$store.getters['user/hasPermissions']('create')"
            >Create</vs-button
          >
        </div>
        <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
            <data-table
              :detail="detail"
              :statusFilter="statusFilter"
              :customerCategoryId="customerCategoryId"
              :territorryId="territorryId"
              :statusApproval="'noo-shipto-open'"
              @close="handleClose"
              @edit="handleEdit"
            ></data-table>
          </div>
          <!-- form -->
          <transition name="detail-fade">
            <div
              v-if="detail"
              v-bind:class="[detail ? detailShow : '', detailHide]"
            >
              <vs-row>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    class="ml-auto"
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>
                <vs-col
                  class="vertical-divider"
                  vs-offset="0"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                >
                  <vs-button
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                  ></vs-button>
                </vs-col>

                <!-- form component -->
                <form-component
                  @close="handleClose"
                  :customerAddressId="customerAddressId"
                  :statusApproval="statusApproval"
                  :showButtonSubmit="showButtonSubmit"
                ></form-component>
              </vs-row>
            </div>
          </transition>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
            <data-table
              :detail="detail"
              :statusFilter="statusFilter"
              @close="handleClose"
              @edit="handleEdit"
              :customerCategoryId="customerCategoryId"
              :territorryId="territorryId"
              :statusApproval="'02'"
            ></data-table>
          </div>
          <!-- form -->
          <transition name="detail-fade">
            <div
              v-if="detail"
              v-bind:class="[detail ? detailShow : '', detailHide]"
            >
              <vs-row>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    class="ml-auto"
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>
                <vs-col
                  class="vertical-divider"
                  vs-offset="0"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                >
                  <vs-button
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                  ></vs-button>
                </vs-col>

                <!-- form component -->
                <form-component
                  @close="handleClose"
                  :customerAddressId="customerAddressId"
                  :statusApproval="statusApproval"
                  :showButtonSubmit="showButtonSubmit"
                ></form-component>
              </vs-row>
            </div>
          </transition>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
            <data-table
              :detail="detail"
              :statusFilter="statusFilter"
              @close="handleClose"
              @edit="handleEdit"
              :customerCategoryId="customerCategoryId"
              :territorryId="territorryId"
              :statusApproval="'03'"
            ></data-table>
          </div>
          <!-- form -->
          <transition name="detail-fade">
            <div
              v-if="detail"
              v-bind:class="[detail ? detailShow : '', detailHide]"
            >
              <vs-row>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    class="ml-auto"
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>
                <vs-col
                  class="vertical-divider"
                  vs-offset="0"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                >
                  <vs-button
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                  ></vs-button>
                </vs-col>

                <!-- form component -->
                <form-component
                  @close="handleClose"
                  :customerAddressId="customerAddressId"
                  :statusApproval="statusApproval"
                  :showButtonSubmit="showButtonSubmit"
                ></form-component>
              </vs-row>
            </div>
          </transition>
        </div>
      </vs-tab>
      <vs-tab label="Inquiry">
        <div class="vx-row mb-12">
          <div v-bind:class="[detail ? detailShow : '', detailHide]">
            <data-table
              :detail="detail"
              :statusFilter="statusFilter"
              @close="handleClose"
              @edit="handleEdit"
              :customerCategoryId="customerCategoryId"
              :territorryId="territorryId"
              :statusApproval="'4'"
            ></data-table>
          </div>
          <!-- form -->
          <transition name="detail-fade">
            <div
              v-if="detail"
              v-bind:class="[detail ? detailShow : '', detailHide]"
            >
              <vs-row>
                <vs-col
                  style="padding-bottom: 8px"
                  vs-offset="8"
                  vs-type="flex"
                  vs-justify="rigth"
                  vs-align="rigth"
                  vs-w="4"
                >
                  <vs-button
                    class="ml-auto"
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                    >Close</vs-button
                  >
                </vs-col>
                <vs-col
                  class="vertical-divider"
                  vs-offset="0"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="1"
                >
                  <vs-button
                    size="small"
                    v-on:click="handleClose"
                    color="grey"
                    icon-pack="feather"
                    icon="icon-x-square"
                  ></vs-button>
                </vs-col>

                <!-- form component -->
                <form-component
                  @close="handleClose"
                  :customerAddressId="customerAddressId"
                  :statusApproval="'4'"
                  :showButtonSubmit="showButtonSubmit"
                ></form-component>
              </vs-row>
            </div>
          </transition>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import FormComponent from "./form";
import DataTable from "./DataTable";
export default {
  components: {
    FormComponent,
    DataTable
  },
  data() {
    return {
      title: "Customer Address",
      showApproval: true,
      showButtonSubmit: false,
      statusApproval: "",
      customerCategoryId: null,
      territorryId: null,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      customerAddressId: null,
      statusFilter: 0,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
    };
  },
  methods: {
    setTabActive(val) {
      this.$emit("tab", val);
    },
    handleClose() {
      this.detail = false;
    },
    handleCreate() {
      this.customerAddressId = null;
      this.detail = true;
      this.showButtonSubmit = true;
    },
    handleEdit(id, status_approval) {
      console.log(id, status_approval)
      this.customerAddressId = id;
      this.detail = true;
      this.statusApproval = status_approval
      this.showButtonSubmit = false
    },
    getData() {
      this.statusFilter += 1;
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-customer-shipto");
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
